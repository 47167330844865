import * as React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';


import Layout from "../components/Layout/Layout"
import Seo from "../components/seo";
import ContactForm from "../components/Contact/ContactForm";
import {Container, Col, Row, Card} from "react-bootstrap";
import PageBanner from "../components/UI/PageBanner";

const DemoPage = () => (
  <Layout>
    <Seo title="Contact" />
      <div>
        <PageBanner heading="Request A Demo"></PageBanner>
        <ContactForm subTitle="Connect with us for a demo, get answers to your specific questions."></ContactForm>       
      </div>

  </Layout>
)

export default DemoPage
